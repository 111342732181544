// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agronomist-js": () => import("./../../../src/pages/agronomist.js" /* webpackChunkName: "component---src-pages-agronomist-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-crops-js": () => import("./../../../src/pages/crops.js" /* webpackChunkName: "component---src-pages-crops-js" */),
  "component---src-pages-dairy-farmer-js": () => import("./../../../src/pages/dairy-farmer.js" /* webpackChunkName: "component---src-pages-dairy-farmer-js" */),
  "component---src-pages-dealer-js": () => import("./../../../src/pages/dealer.js" /* webpackChunkName: "component---src-pages-dealer-js" */),
  "component---src-pages-farmer-js": () => import("./../../../src/pages/farmer.js" /* webpackChunkName: "component---src-pages-farmer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livestock-js": () => import("./../../../src/pages/livestock.js" /* webpackChunkName: "component---src-pages-livestock-js" */)
}

